import request, {request_file, showError} from '@/utils/request'

// 邮箱登录
export function loginByEmail(data) {
    return request({
        url: 'user/loginbyemail',
        method: 'post',
        data
    })
}

// 用户注册
export function signUp(data) {
    return request({
        url: 'user/signup',
        method: 'post',
        data
    })
}

// 创建用户
export function createUser(data) {
    return request({
        url: 'user/create',
        method: 'post',
        data
    })
}

// 编辑用户信息
export function editUserInfo(data) {
    return request({
        url: 'user/editinfo',
        method: 'post',
        data
    })
}

// 忘记密码（发送邮件）
export function forgetPassword(data) {
    return request({
        url: 'user/forgetpassword',
        method: 'post',
        data
    })
}

// 重置密码
export function resetPassword(data) {
    return request({
        url: 'user/resetpassword',
        method: 'post',
        data
    })
}

// 修改密码
export function editPassword(data) {
    return request({
        url: 'user/editpassword',
        method: 'post',
        data
    })
}

// 修改邮箱
export function editEmail(data) {
    return request({
        url: 'user/editemail',
        method: 'post',
        data
    })
}

// 修改邮箱第二部验证码
export function checkEmailCode(data) {
    return request({
        url: 'user/editveremailcode',
        method: 'post',
        data
    })
}

// 退出登录
export function userOut(data) {
    return request({
        url: 'user/out',
        method: 'post',
        data
    })
}

// 谷歌登录
export function loginByGoogle(data) {
    return request({
        url: 'user/loginbygoogle',
        method: 'post',
        data
    })
}

// 谷歌注册
export function signupByGoogle(data) {
    return request({
        url: 'user/signbygoogle',
        method: 'post',
        data
    })
}

// 管理员获取团队的成员
export function getUserList(data) {
    return request({
        url: 'user/users',
        method: 'post',
        data
    })
}

// 管理员编辑用户信息
export function editUser(data) {
    return request({
        url: 'user/edit',
        method: 'post',
        data
    })
}

// 管理员删除用户
export function deleteUser(data) {
    return request({
        url: 'user/delete',
        method: 'post',
        data
    })
}

// 创建邀请码
export function createUserInvite(data) {
    return request({
        url: 'user/getinvite',
        method: 'post',
        data
    })
}

// 解绑谷歌发送邮箱验证码
export function unlinkSendCode(data) {
    return request({
        url: 'user/unlinksendcode',
        method: 'post',
        data
    })
}

// 解绑谷歌校验验证码
export function unlinkCheckCode(data) {
    return request({
        url: 'user/unlinkcheckeamil',
        method: 'post',
        data
    })
}

// 解绑谷歌修改密码
export function unlinkChangePassword(data) {
    return request({
        url: 'user/unlinkeditpassword',
        method: 'post',
        data
    })
}

// 用户用谷歌绑定现在账号
export function bindGoogle(data) {
    return request({
        url: 'user/bindwithgoogle',
        method: 'post',
        data
    })
}

// 获取用户信息
export function getUserInfo() {
    return request({
        url: 'user/info',
        method: 'post',
        data: ''
    })
}

// 上传头像
export function uploadAva(data) {
    return request_file.post('user/uploadlogo', data).catch(error => {showError(error)})
}
